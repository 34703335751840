<template>
    <div class="bg-white p-5" style="margin: 30px; border-radius: 10px; min-height: calc(100vh - 300px)">
        <h1>Netzwerk {{ network ? network.id : '' }}</h1>
    </div>
</template>

<script>
import { Network } from '@/core/services/store/models/network'

export default {
    name: 'NetworkSingle',
    computed: {
        network() {
            const network = Network.find(this.$route.params.id)
            if (!network) { Network.fetchAll() }
            return network
        }
    }
}
</script>

<style scoped>

</style>
